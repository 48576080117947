import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import algoliasearch from 'algoliasearch';
import SearchResult from "./SearchResult";
export default function SearchContainer(props) {
    const { locale, domain } = props;
    const [result, setResult] = useState([]);
    const InputRef = useRef(null);
    const [index, setIndex] = useState(null);
    useEffect(() => {
        if (process.env.MIX_ALGOLIA_APP_ID && process.env.MIX_ALGOLIA_SECRET) {
            const client = algoliasearch(process.env.MIX_ALGOLIA_APP_ID, process.env.MIX_ALGOLIA_SECRET);
            setIndex(client.initIndex("search"));
        }
    }, []);
    useEffect(() => {
        let searchBtn = document.getElementById('searchBtn');
        if (searchBtn && InputRef.current) {
            searchBtn.addEventListener('click', function () {
                setResult([]);
                InputRef.current.value = "";
            });
        }
    }, [InputRef]);
    const submitForm = function (e) {
        e.preventDefault();
        //window.location.href = `/${locale}/search/${InputRef.current.value}`
    };
    const handleChange = function (e) {
        if (e.target.value) {
            search(e.target.value);
        }
        else {
            setResult([]);
        }
    };
    const hasResults = useMemo(() => {
        return result && result.hits && result.hits.length > 0;
    }, [result]);
    const noResults = useMemo(() => {
        if (!InputRef.current) {
            return false;
        }
        if (!InputRef.current.value) {
            return false;
        }
        return (hasResults === false) ? true : false;
    }, [hasResults, InputRef.current]);
    const search = function (query) {
        index.setSettings({
            attributesForFaceting: [
                'filterOnly(locale)',
                'filterOnly(domain)',
            ],
            searchableAttributes: [
                'title, description, category, formats, markers, distributors',
            ]
        }).then(() => {
            index.search(query, {
                filters: `locale:${locale} AND domain:${domain}`,
                hitsPerPage: 10
            }).then((response) => {
                setResult(response);
            });
        });
    };
    const disabledForm = useMemo(() => {
        if (!result.hits) {
            return true;
        }
        return (result.nbHits > result.hits.length) ? false : true;
    }, [result]);
    return (React.createElement("div", { className: "container" },
        React.createElement("div", { className: "search-container" },
            React.createElement("h2", null, "Que recherchez vous ?"),
            React.createElement("form", { autoComplete: "off", onSubmit: submitForm },
                React.createElement("div", { className: "input-container" },
                    React.createElement("input", { ref: InputRef, id: "query-input", onChange: handleChange, name: "query", type: "text", placeholder: "Mot clef" }),
                    React.createElement("svg", { width: "29", height: "29", viewBox: "0 0 29 29", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M12.3181 21.6C7.46123 21.6 3.51945 17.6576 3.51945 12.8C3.51945 7.94766 7.46123 3.99998 12.3181 3.99998C17.1749 3.99998 21.1167 7.94766 21.1167 12.8C21.1167 17.6576 17.1749 21.6 12.3181 21.6ZM27.6453 25.6304L22.1549 20.192C23.7035 18.1328 24.6362 15.5808 24.6362 12.8C24.6362 6.00814 19.1106 0.47998 12.3181 0.47998C5.52554 0.47998 0 6.00814 0 12.8C0 19.5936 5.52554 25.12 12.3181 25.12C15.0984 25.12 17.65 24.1872 19.7089 22.6384V22.656L25.1465 28.1296C25.4984 28.464 25.9383 28.64 26.3959 28.64C26.8534 28.64 27.2933 28.464 27.6453 28.1296C28.3316 27.4432 28.3316 26.3168 27.6453 25.6304Z", fill: "gray" })))),
            hasResults &&
                React.createElement(SearchResult, { hits: result.hits }),
            noResults &&
                React.createElement("div", { className: "search-result no-result" }, "Il n'y a pas de r\u00E9sultat pour votre recherche"))));
}
